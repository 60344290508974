@font-face {
  font-family: ClashDisplay-Variable;
  src: url("ClashDisplay-Variable.01b7936e.woff2") format("woff2"), url("ClashDisplay-Variable.59fcd8ed.woff") format("woff"), url("ClashDisplay-Variable.203a6647.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: ClashDisplay-Extralight;
  src: url("ClashDisplay-Extralight.02538bf7.woff2") format("woff2"), url("ClashDisplay-Extralight.c4101349.woff") format("woff"), url("ClashDisplay-Extralight.30fe923e.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: ClashDisplay-Light;
  src: url("ClashDisplay-Light.3efd8ec8.woff2") format("woff2"), url("ClashDisplay-Light.1924f4a8.woff") format("woff"), url("ClashDisplay-Light.ec13b7ae.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: ClashDisplay-Regular;
  src: url("ClashDisplay-Regular.f8c1bae1.woff2") format("woff2"), url("ClashDisplay-Regular.d934af4a.woff") format("woff"), url("ClashDisplay-Regular.3ae6a033.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: ClashDisplay-Medium;
  src: url("ClashDisplay-Medium.be6a754f.woff2") format("woff2"), url("ClashDisplay-Medium.c817a3fc.woff") format("woff"), url("ClashDisplay-Medium.c722c311.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: ClashDisplay-Semibold;
  src: url("ClashDisplay-Semibold.605c03dc.woff2") format("woff2"), url("ClashDisplay-Semibold.d037ff9c.woff") format("woff"), url("ClashDisplay-Semibold.7279b5bc.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: ClashDisplay-Bold;
  src: url("ClashDisplay-Bold.ca296818.woff2") format("woff2"), url("ClashDisplay-Bold.b383f53b.woff") format("woff"), url("ClashDisplay-Bold.85878539.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

/*# sourceMappingURL=index.12f7fe5c.css.map */
